import Vue from "vue";
import Router from "vue-router";
import { api } from "@/state/services";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "/extrato",
    },
    {
      path: "/login",
      component: () => import("./views/login/index"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: false,
      },
    },
    {
      path: "/login/token/:token",
      component: () => import("./views/login/token"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: false,
      },
    },
    {
      path: "/logout",
      redirect: "/login",
    },
    {
      path: "/login/recuperar-senha",
      component: () => import("./views/login/senha-recuperar"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: false,
      },
    },
    {
      path: "/login/alterar-senha",
      component: () => import("./views/login/senha-alterar"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: false,
      },
    },
    {
      path: "/configuracoes",
      component: () => import("./views/configuracoes/index"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/configuracoes/alterar-senha",
      component: () => import("./views/configuracoes/alterar-senha"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/extrato",
      component: () => import("./views/extrato/index"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/transferir",
      component: () => import("./views/transferir/index"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/cobrancas",
      component: () => import("./views/cobrancas/index"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/cobrancas/criar",
      component: () => import("./views/cobrancas/criar"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/cobrancas/importar",
      component: () => import("./views/cobrancas/importar"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/favorecidos",
      component: () => import("./views/favorecidos/index"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/favorecidos/cadastrar",
      component: () => import("./views/favorecidos/cadastrar"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/favorecidos/importar",
      component: () => import("./views/favorecidos/importar"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/favorecidos/importar/:id",
      component: () => import("./views/favorecidos/importar_arquivo"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/favorecidos/:id",
      component: () => import("./views/favorecidos/editar"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/pagamentos",
      component: () => import("./views/pagamentos/index"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/pagamentos/criar",
      component: () => import("./views/pagamentos/criar"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/pagamentos/importar",
      component: () => import("./views/pagamentos/importar"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/pagamentos/importar/:id",
      component: () => import("./views/pagamentos/importar_arquivo"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/relatorios",
      component: () => import("./views/relatorios/index"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/404",
      component: () => import("./views/404"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: false,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("token");

  document.title = to.meta.title;
  next();

  if (to.meta.authRequired && !loggedIn) {
    return next("/login");
  }

  if (to.meta.authRequired && loggedIn) {
    api
      .get("user")
      .then((response) => {
        if (!response.data && !response.data.status == "success") {
          return next("/login");
        }
      })
      .catch(error => {
        if (error) {
          return next("/login");
        }
      });
  } else {
    next();
  }
});

export default router;
